/* eslint-disable react-hooks/exhaustive-deps*/

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Terminal } from '@xterm/xterm';
import '@xterm/xterm/css/xterm.css';
import { AttachAddon } from '@xterm/addon-attach';
import { FitAddon } from '@xterm/addon-fit';

const TerminalComponent = ({ serverData, reload, setReloadSSHWindow }) => {

    const terminalRef = useRef(null);
    const [terminal, setTerminal] = useState(null);

    // Memoize the FitAddon instance
    const fitAddon = useMemo(() => new FitAddon(), []);
    // Memoize the WebSocket setup
    const setupWebSocket = (terminal) => {
        const socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}`);
        let intervalId;
        socket.onopen = () => {
            const dataSend = { auth: { ...serverData } };
            socket.send(JSON.stringify(dataSend));
            terminal.loadAddon(new AttachAddon(socket));

            intervalId = setInterval(() => {
                if (socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ refresh: "" }));
                }
            }, 700);
        };

        terminal.onData(data => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({ data: { data } }));
            }
        });

        const cleanup = () => {
            if (socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
            clearInterval(intervalId);
        };

        return cleanup;
    };

    // Memoize the terminal initialization function
    const initializeTerminal = () => {
        if (terminal) {
            terminal.dispose(); // Dispose of the current terminal instance
            terminalRef.current.innerHTML = ''; // Clear the terminal container
        }

        const newTerminal = new Terminal({
            cols: 119,
            rows: 29,
            theme: {
                background: '#1d1f21',
                foreground: 'white',
                cursor: 'green',
                selection: 'rgba(255, 255, 255, 0.3)',
            }
        });

        setTerminal(newTerminal);
        newTerminal.loadAddon(fitAddon);
        newTerminal.open(terminalRef.current);
        fitAddon.fit();
        newTerminal.focus();

        return setupWebSocket(newTerminal);
    };

    useEffect(() => {
        const handleResize = () => fitAddon.fit();

        window.addEventListener('resize', handleResize);
        const cleanup = initializeTerminal();

        return () => {
            window.removeEventListener('resize', handleResize);
            cleanup(); // Ensure cleanup is called to dispose WebSocket connections
        };
    }, []);

    useEffect(() => {
        if (reload) {
            setReloadSSHWindow(false); // Reset reload trigger
            const cleanup = initializeTerminal(); // Re-initialize terminal
            return cleanup; // Cleanup on component unmount or before reinitializing
        }
    }, [reload]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div ref={terminalRef} style={{ width: '100%', height: '100%' }}></div>
        </div>
    );
};

export default TerminalComponent;
