import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Backdrop, Box, Card, CardActions, CardContent, Container, debounce, Divider, Fade, Modal, Paper, StyledEngineProvider, TableContainer, TablePagination, Toolbar, Tooltip, Typography } from '@mui/material'
import { SearchElement } from '../../components/Search/Search'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDeleteMicroServiceMutation, useGetMicroServiceQuery } from '../../redux/apis/MicroServiceApi'
import AddEditService from './AddEditService'
import MicroServicesDeploy from './Deploy'
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox'
import PusherComponent from '../../components/PusherComponent/PusherComponent'
import EditIcon from "@mui/icons-material/Edit";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
    DeleteForeverRounded
} from "@mui/icons-material";

const tableColumns = [
    // {
    //     id: "id",
    //     sort: false,
    //     label: "S.No.",
    // },
    {
        id: "microservice_name",
        sort: true,
        label: "Service Name",
    },
    {
        id: "pipeline_name",
        sort: true,
        label: "Pipeline Name",
    },
    {
        id: "pipeline_status",
        sort: false,
        label: "Pipeline Status"
    },
    {
        id: "job_name",
        sort: true,
        label: "Job Name",
    },
    {
        id: "job_status",
        sort: false,
        label: "Job Status"
    },
    {
        id: "fail_reason",
        sort: false,
        label: "Fail Reason"
    },
    {
        id: "trigger_by",
        sort: false,
        label: "Trigger By"
    },
    {
        id: "trigger_time",
        sort: false,
        label: "Trigger Time"
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];
let initialOrder = process.env.REACT_APP_ORDER;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 10,
    borderRadius: 2,
};

const MicroServicesDashboard = () => {
    const initialState = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE) ?? 20,
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };

    let navigate = useNavigate();
    let query = useLocation();

    const [isClose, setIsClose] = useState(false);
    const [Id, setId] = useState("");
    const [openEdit, setOpenEdit] = useState(false);

    const [openDeploy, setOpenDeploy] = useState(false);
    const [deployServiceData, setDeployServiceData] = useState(null);

    const [pusherData, setPusherData] = useState(null);
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(initialState.per_page);
    const [pageMetaData, setPageMetaData] = useState(initialState);
    const [microServiceListData, setMicroServiceListData] = useState({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });

    const MicroServiceListData = useGetMicroServiceQuery({
        per_page: pageMetaData.per_page ?? rowsPerPage,
        page: query.state ?? pageMetaData.page,
        order: pageMetaData.order ?? process.env.REACT_APP_ORDER,
        order_by: pageMetaData.order_by ?? process.env.REACT_APP_ORDER_BY,
        searchText: pageMetaData.searchText ?? search,
    });
    const [deleteMicroService] = useDeleteMicroServiceMutation();

    useEffect(() => {
        if (MicroServiceListData?.data?.success) {
            setMicroServiceListData(MicroServiceListData?.data?.data ?? [])
            setMicroServiceListData({
                data: MicroServiceListData?.data?.data?.data ?? [],
                page: MicroServiceListData?.data?.data?.current_page - 1,
                last_page: MicroServiceListData?.data?.data?.last_name,
                total: MicroServiceListData?.data?.data?.total,
            });
        }
    }, [MicroServiceListData]);

    useEffect(() => {
        if (pusherData?.length > 0) {
            const updatedArray = microServiceListData?.data?.length > 0 && microServiceListData?.data?.map(item1 => {
                const match = pusherData?.find(item2 => item2?.job_id === item1?.job_id);
                return match ? {
                    ...item1,
                    job_name: match?.job_name,
                    job_status: match?.job_status,
                    job_url: match?.job_url,
                    fail_reason: match?.fail_reason,
                } : item1;
            });

            setMicroServiceListData({ ...microServiceListData, data: updatedArray ?? microServiceListData?.data });
        }
    }, [pusherData, microServiceListData]);

    const memoizedSetOpen = useCallback((value) => setOpenEdit(value), []);
    const memoizedSetId = useCallback((value) => setId(value), []);

    const memoizedSetPusherData = useCallback((value) => setPusherData(value), []);
    const memoizedSetOpenDeploy = useCallback((value) => setOpenDeploy(value), []);

    const handleChangePage = (event, newPage) => {
        setPageMetaData({ ...pageMetaData, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageMetaData({
            ...pageMetaData,
            per_page: parseInt(event.target.value),
            page: 1,
        });
    };

    const searchHandler = useMemo(() => {
        return debounce((event) => {
            setSearch(event.target.value);
            setPageMetaData({
                page: 1,
                per_page: pageMetaData?.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, [pageMetaData]);

    const confirmBoxClose = (res) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteMicroService(Id);
        } else {
            setIsClose(res);
        }
    };

    return (
        <Paper className="table-outers">
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 40%' }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                    className="inner-headings"
                >
                    MicroService Dashboard
                </Typography>

                <div className="flex-boxinner">
                    <Typography
                        sx={{ flex: '1 1 auto' }}
                    >
                        <SearchElement
                            searchFn={searchHandler}
                            searchTag={`Search ...`}
                        />
                    </Typography>
                </div>
            </Toolbar>
            <StyledEngineProvider>
                <TableContainer component={Paper} className="table-outers" style={{ maxHeight: 700, overflowY: 'auto' }}>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        style={{ marginLeft: "2rem" }}
                        sx={{
                            gap: { xs: 2, sm: 3, md: 4, lg: 5 },
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            padding: 2
                        }}
                    >
                        {microServiceListData?.data?.length > 0 && microServiceListData?.data?.map((service) => (
                            <Card
                                key={service.id}
                                sx={{
                                    width: 350,
                                    padding: "8px 0px",
                                    boxShadow: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CardContent>
                                    <Typography gutterBottom variant="h5" textAlign="center">
                                        {service?.microservice_name?.charAt(0).toUpperCase() + service?.microservice_name?.slice(1)} Information
                                    </Typography>
                                    <Divider sx={{ marginY: 1 }} />
                                    <Box>
                                        {tableColumns.map((column) => (
                                            column.id !== 'action' && (
                                                <Box
                                                    key={column.id}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    paddingY={0.5}
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        fontWeight="bold"
                                                        sx={{
                                                            fontSize: '0.95rem',
                                                            letterSpacing: '0.05rem',
                                                            flex: 1,
                                                        }}
                                                    >
                                                        {column.label}:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            fontSize: '0.95rem',
                                                            letterSpacing: '0.05rem',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {service[column.id] || '-'}
                                                    </Typography>
                                                </Box>
                                            )
                                        ))}
                                    </Box>
                                </CardContent>
                                <Divider sx={{ marginY: 1, borderWidth: 2, borderColor: 'gray' }} />
                                <CardActions sx={{ justifyContent: 'center', gap: 4 }}>
                                    <Tooltip title="Run Deploy" arrow enterDelay={300} leaveDelay={200} placement="left">
                                        <RocketLaunchIcon
                                            onClick={() => { setOpenDeploy(true); setDeployServiceData(service); }}
                                            className='mircro-service-icon'
                                            style={{ color: "#1976d2" }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Edit" arrow enterDelay={300} leaveDelay={200} placement="bottom">
                                        <EditIcon
                                            onClick={() => { setId(service.id); setOpenEdit(true); }}
                                            className='mircro-service-icon'
                                            style={{ color: "#1976d2" }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Delete" arrow enterDelay={300} leaveDelay={200} placement="right">
                                        <DeleteForeverRounded
                                            onClick={() => { setIsClose(true); setId(service.id); }}
                                            className='mircro-service-icon'
                                            style={{ color: "red" }}
                                        />
                                    </Tooltip>
                                </CardActions>
                            </Card>
                        ))}
                    </Box>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={microServiceListData?.total ?? 0}
                    page={microServiceListData?.page ?? 0}
                    rowsPerPageOptions={[20, 50, 100]}
                    onPageChange={handleChangePage}
                    rowsPerPage={
                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </StyledEngineProvider>
            {
                openEdit &&
                <Container maxWidth={false} className='p-0' >
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openEdit}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openEdit}>
                            <Box sx={style}>
                                <AddEditService id={Id} setOpen={memoizedSetOpen} setId={memoizedSetId} />
                            </Box>
                        </Fade>
                    </Modal>
                </Container>
            }

            {
                openDeploy &&
                <Container maxWidth={false} className='p-0' >
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openDeploy}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={openDeploy}>
                            <Box sx={style}>
                                <MicroServicesDeploy data={deployServiceData} setOpenDeploy={memoizedSetOpenDeploy} />
                            </Box>
                        </Fade>
                    </Modal>
                </Container>
            }
            <ConfirmBox
                title="MicroService Delete Confirmation"
                message="Are you sure, you want to delete this MicroService?"
                open={isClose}
                onClose={confirmBoxClose}
            />
            <PusherComponent setPusherData={memoizedSetPusherData} />
        </Paper >
    )
}

export default MicroServicesDashboard