import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Divider, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useGetAtlasServerLoginQuery } from '../../redux/apis/AtlasIOApi'
import AtlasTableComponent from './AtlasTableComponent'
import { useSelector } from 'react-redux'

const tableColumns = [
    {
        id: "user",
        sort: false,
        label: "Username",
    },
    {
        id: "password",
        sort: false,
        label: "Password",
    },
    {
        id: "port",
        sort: false,
        label: "Port Number",
    },
    {
        id: "login_type",
        sort: false,
        label: "Login Type",
    },
    {
        id: "login_notes",
        sort: false,
        label: "Login Notes",
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

const AtlasServerColumns = [
    {
        id: "product_category",
        sort: false,
        label: "Product Category",
    },
    {
        id: "stack_type",
        sort: false,
        label: "Stack Type",
    },
    {
        id: "created_at",
        sort: false,
        label: "Server Created",
    },
    {
        id: "updated_at",
        sort: false,
        label: "Server Updated",
    },
]

let initialOrder = process.env.REACT_APP_ORDER;

const AtlasServerLogin = () => {
    const initialState = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE) ?? 20,
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };

    let navigate = useNavigate();
    const serverDetail = useSelector(state => state.atlas.server_detail);

    const [orderBy, setOrderBy] = useState(process.env.REACT_APP_ORDER_BY);
    const [order, setOrder] = useState(initialOrder);
    const [atlasServerList, setAtlasServerList] = useState(initialState);
    const [atlasRootLoginData, setAtlasRootLoginData] = useState({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });
    const [atlasUserLoginData, setAtlasUserLoginData] = useState({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });
    const [atlasServerDetail, setAtlasServerDetail] = useState(null);

    const { data: UserListApiCall } = useGetAtlasServerLoginQuery(serverDetail?.id,
        { skip: !serverDetail?.id }
    );

    useEffect(() => {
        if (serverDetail?.id === undefined) {
            navigate('/atlasio');
        } else {
            setAtlasServerDetail(serverDetail);
        }
    }, [serverDetail, navigate]);

    useEffect(() => {
        if (UserListApiCall?.success) {
            let data = UserListApiCall?.data?.length > 0 ? UserListApiCall?.data?.map((item) => ({
                ...item,
                server_ip: (atlasServerDetail?.hostname + ' (' + atlasServerDetail?.ip_address + ')') ?? ''
            })) : []
            const rootData = data.filter((item) => item.user === 'root');
            const userData = data.filter((item) => item.user !== 'root');
            setAtlasRootLoginData({
                data: rootData,
                page: 0,
                last_page: 1,
                total: rootData?.length,
            });
            setAtlasUserLoginData({
                data: userData,
                page: 0,
                last_page: 1,
                total: userData?.length,
            })

        }
    }, [UserListApiCall, atlasServerDetail]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setAtlasServerList({
            ...atlasServerList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (event, property) => {
        if (property !== ("action")) {
            handleRequestSort(event, property);
        }
    };

    const customizeTime = (isoDate) => {
        const date = new Date(isoDate);

        const fullFormattedDate = date.toLocaleString("en-US", {
            // weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            // timeZoneName: "short",
        });
        return fullFormattedDate;
    }

    return (
        <Paper className="table-outers">
            <Box
                display="flex"
                flexWrap="wrap"
                style={{ margin: "1rem" }}
                sx={{
                    gap: { xs: 2, sm: 3, md: 4, lg: 5 },
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    padding: 2
                }}
            >
                <Card sx={{ padding: 3, gap: 2, width: "100%" }}>
                    <Typography
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        Server Login {'(' + serverDetail?.hostname + ' - ' + serverDetail?.ip_address + ')'}
                    </Typography>
                    <AtlasTableComponent
                        cardTitle="Root Logins"
                        tableColumns={tableColumns}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        selectedColumns={[
                            'user',
                            'password',
                            'port',
                            'login_type',
                            'login_notes'
                        ]}
                        atlasServerListData={atlasRootLoginData}
                    />
                    <AtlasTableComponent
                        cardTitle="User Logins"
                        tableColumns={tableColumns}
                        order={order}
                        orderBy={orderBy}
                        createSortHandler={createSortHandler}
                        selectedColumns={[
                            'user',
                            'password',
                            'port',
                            'login_type',
                            'login_notes'
                        ]}
                        atlasServerListData={atlasUserLoginData}
                    />
                </Card>
                <Box sx={{ width: "100%" }}>
                    <Divider sx={{ marginY: 1, borderWidth: 2, borderColor: 'gray' }} />
                </Box>
                <Box className="additionalServerInfo">
                    <Box className="additional-info">

                        {/* Additional Server Information */}
                        <Card
                            sx={{
                                width: '100%',
                                padding: { xs: '8px 4px', sm: '8px 8px' }, // Adjust padding for mobile and larger screens
                                boxShadow: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    textAlign="center"
                                    sx={{
                                        fontSize: { xs: '1.25rem', sm: '1.5rem' }, // Responsive font size
                                    }}
                                >
                                    Server Information
                                </Typography>
                                <Divider sx={{ marginY: 1 }} />
                                <Box>
                                    {AtlasServerColumns.map(
                                        (column) =>
                                            column.id !== 'action' && (
                                                <Box
                                                    key={column.id}
                                                    display="flex"
                                                    flexDirection={{ xs: 'column', sm: 'row' }} // Stack items on smaller screens
                                                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                                                    justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                                                    paddingY={0.5}
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        fontWeight="bold"
                                                        sx={{
                                                            fontSize: { xs: '0.9rem', sm: '0.95rem' }, // Adjust font size
                                                            letterSpacing: '0.05rem',
                                                            flex: 1,
                                                            textAlign: { xs: 'left', sm: 'left' },
                                                            marginBottom: { xs: '4px', sm: 0 }, // Add spacing between label and value on mobile
                                                        }}
                                                    >
                                                        {column.label}:
                                                    </Typography>
                                                    {column.id.includes('created_at') || column.id.includes('updated_at') ?
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{
                                                                fontSize: { xs: '0.85rem', sm: '0.95rem' }, // Adjust font size
                                                                letterSpacing: '0.05rem',
                                                                textAlign: { xs: 'left', sm: 'right' },
                                                            }}
                                                        >
                                                            {atlasServerDetail?.[column.id] !== null
                                                                ? customizeTime(atlasServerDetail?.[column.id])
                                                                : '-'}
                                                        </Typography> :
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{
                                                                fontSize: { xs: '0.85rem', sm: '0.95rem' }, // Adjust font size
                                                                letterSpacing: '0.05rem',
                                                                textAlign: { xs: 'left', sm: 'right' },
                                                            }}
                                                        >
                                                            {atlasServerDetail?.[column.id] !== null
                                                                ? atlasServerDetail?.[column.id]
                                                                : '-'}
                                                        </Typography>}
                                                </Box>
                                            )
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                width: '100%',
                                padding: { xs: '8px 4px', sm: '8px 8px' }, // Adjust padding for mobile and larger screens
                                boxShadow: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    textAlign="center"
                                    sx={{
                                        fontSize: { xs: '1.25rem', sm: '1.5rem' }, // Responsive font size
                                    }}
                                >
                                    Additional IP Addresses
                                </Typography>
                                <Divider sx={{ marginY: 1 }} />
                                <Box>
                                    {serverDetail?.addition_ips !== null ?
                                        <Box
                                            sx={{
                                                fontSize: '0.85rem',
                                                overflowX: 'auto', // Enable horizontal scrolling
                                                maxWidth: '100%', // Ensure it doesn't exceed the container width
                                            }}
                                        >
                                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                <tbody>
                                                    {serverDetail?.addition_ips?.split("\n").map((ip, index) => (
                                                        <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                                                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{ip}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Box>
                                        :
                                        <Box style={{ textAlign: "center", justifyContent: "center", width: "100%" }}>
                                            <h5>No Record found</h5>
                                        </Box>
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                        {/* Domains Hosted */}
                        <Card sx={{
                            width: '100%',
                            padding: "8px 0px",
                            boxShadow: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" textAlign="center"
                                    sx={{
                                        fontSize: { xs: '1.25rem', sm: '1.5rem' }, // Responsive font size
                                    }}
                                >
                                    Domain Hosted
                                </Typography>
                                <Divider sx={{ marginY: 1 }} />
                                <Box>
                                    {atlasServerDetail?.domains_hosted ? (
                                        <Box
                                            sx={{
                                                fontSize: '0.85rem',
                                                overflowX: 'auto', // Enable horizontal scrolling
                                                maxWidth: '100%', // Ensure it doesn't exceed the container width
                                            }}
                                        >
                                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: '#f2f2f2' }}>
                                                        <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Domain</th>
                                                        <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Username</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {JSON.parse(atlasServerDetail?.domains_hosted).domains.map((domain, index) => (
                                                        <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                                                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                                <a href={domain.domain} target="_blank" rel="noopener noreferrer">
                                                                    {domain.domain}
                                                                </a>
                                                            </td>
                                                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{domain.username}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Box>
                                    ) : (
                                        <Box style={{ textAlign: 'center', justifyContent: 'center', width: '100%' }}>
                                            <h5>No Record found</h5>
                                        </Box>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    {/* Server Notes */}
                    <Card className='server-notes'>
                        <CardContent>
                            <Typography gutterBottom variant="h5" textAlign="center"
                                sx={{
                                    fontSize: { xs: '1.25rem', sm: '1.5rem' }, // Responsive font size
                                }}
                            >
                                Server Notes
                            </Typography>
                            <Divider sx={{ marginY: 1 }} />
                            <Box>
                                {atlasServerDetail?.server_notes ?
                                    <Box
                                        sx={{ fontSize: '0.85rem' }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: atlasServerDetail?.server_notes }} />
                                    </Box> :
                                    <Box style={{ textAlign: "center", justifyContent: "center", width: "100%" }}>
                                        <h5>No Record found</h5>
                                    </Box>
                                }
                            </Box>
                        </CardContent>
                    </Card>

                </Box>
            </Box>
        </Paper >
    )
}

export default AtlasServerLogin