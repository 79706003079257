import React, { useState, useMemo, useEffect } from 'react'
import { Box, Card, CardActions, CardContent, debounce, Divider, Paper, StyledEngineProvider, TableContainer, TablePagination, Toolbar, Tooltip, Typography } from '@mui/material'
import { SearchElement } from '../../components/Search/Search'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetAtlasServerListQuery } from '../../redux/apis/AtlasIOApi'
import { useDispatch, useSelector } from 'react-redux'
import { RemoveRedEye } from '@mui/icons-material'
import { setServerDetail } from '../../redux/slice/AtlasSlice'
import AWSImage from "../../assets/images/awsicon.png";
import AWSLightSailImage from "../../assets/images/awsls.png";
import DigitalOceanImage from "../../assets/images/digitalocean.png";
import WebscootImage from "../../assets/images/webscoot.png";

const tableColumns = [
    {
        id: "ip_address",
        sort: true,
        label: "IP Address",
    },
    {
        id: "hostname",
        sort: true,
        label: "Hostname",
    },
    {
        id: "server_configuration",
        sort: false,
        label: "CPU/RAM/Storage",
    },
    {
        id: "operating_system",
        sort: false,
        label: "Operating System",
    },
    {
        id: "verdor_name",
        sort: false,
        label: "Vendor Name",
    },
    {
        id: "backups",
        sort: false,
        label: "Backups",
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];

const AtlasServerDashboard = () => {
    const initialState = {
        page: 1,
        per_page: 21,
        searchText: "",
        order: 'asc',
        order_by: 'hostname',
    };

    const userPermission = useSelector((state) => state.auth.permissions);
    let navigate = useNavigate();
    let query = useLocation();
    let dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(initialState.per_page);
    const [atlasServerList, setAtlasServerList] = useState(initialState);
    const [atlasServerListData, setAtlasServerListData] = useState({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });

    const { data: UserListApiCall } = useGetAtlasServerListQuery({
        per_page: atlasServerList.per_page ?? rowsPerPage,
        page: query.state ?? atlasServerList.page,
        order: atlasServerList.order,
        order_by: atlasServerList.order_by,
        searchText: atlasServerList.searchText ?? search,
    });

    useEffect(() => {
        if (UserListApiCall?.success) {
            let data = UserListApiCall?.data?.data?.length > 0 ? UserListApiCall?.data?.data : []
            setAtlasServerListData({
                data: data,
                page: UserListApiCall?.data?.current_page - 1,
                last_page: UserListApiCall?.data?.last_name,
                total: UserListApiCall?.data?.total,
            });
        }
    }, [UserListApiCall]);

    const handleChangePage = (event, newPage) => {
        setAtlasServerList({ ...atlasServerList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setAtlasServerList({
            ...atlasServerList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
    };

    const searchHandler = useMemo(() => {
        return debounce((event) => {
            setSearch(event.target.value);
            setAtlasServerList({
                ...atlasServerList,
                page: 1,
                per_page: atlasServerList.per_page,
                order: atlasServerList?.order,
                searchText: event.target.value,
            });
        }, 800);
    }, [atlasServerList]);

    const ImageRender = (vendor) => {
        let imageData = WebscootImage;

        if (vendor === 'AWS-EC2') {
            imageData = AWSImage;
        } else if (vendor === 'DigitalOcean') {
            imageData = DigitalOceanImage;
        } else if (vendor === 'AWS-LS') {
            imageData = AWSLightSailImage;
        }

        return (
            <Box
                component="img"
                src={imageData}
                alt="server"
                sx={{
                    // width: '50px',
                    height: '35px',
                    objectFit: 'contain',
                    display: 'block'
                }}
            />
        );
    };

    return (
        <Paper className="table-outers">
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 40%' }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                    className="inner-headings"
                >
                    Atlas IO
                </Typography>

                <div className="flex-boxinner">
                    <Typography
                        sx={{ flex: '1 1 auto' }}
                    >
                        <SearchElement
                            searchFn={searchHandler}
                            searchTag={`Search Server...`}
                        />

                    </Typography>
                </div>
            </Toolbar>
            <StyledEngineProvider>
                <TableContainer component={Paper} className="table-outers" style={{ maxHeight: 700, overflowY: 'auto' }}>
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        className="tanle-container-box"
                        sx={{
                            gap: { xs: 2, sm: 3, md: 4, lg: 5 },
                            justifyContent: "flex-start !important",
                            alignItems: "flex-start !important",
                            padding: 2
                        }}
                    >
                        {atlasServerListData?.data?.length > 0 && atlasServerListData?.data?.map((service) => (
                            <Card
                                key={service.id}
                                sx={{
                                    width: 450,
                                    padding: "8px 0px",
                                    boxShadow: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CardContent>
                                    <Box display="flex" alignItems="center">
                                        <Box flexShrink={0} sx={{ width: "20%" }}>
                                            {ImageRender(service?.verdor_name)}
                                        </Box>
                                        <Box flexGrow={1} textAlign="center">
                                            <Typography
                                                variant="h5"
                                                gutterBottom
                                                sx={{ margin: 0, width: '80%' }}
                                            >
                                                {service?.hostname?.charAt(0).toUpperCase() + service?.hostname?.slice(1)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ marginY: 1 }} />
                                    <Box>
                                        {tableColumns.map((column) => (
                                            column.id !== 'action' && (
                                                <Box
                                                    key={column.id}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    paddingY={0.5}
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        fontWeight="bold"
                                                        sx={{
                                                            fontSize: '0.95rem',
                                                            letterSpacing: '0.05rem',
                                                            flex: 1,
                                                        }}
                                                    >
                                                        {column.label}:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            fontSize: '0.95rem',
                                                            letterSpacing: '0.05rem',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {service[column.id] || '-'}
                                                    </Typography>
                                                </Box>
                                            )
                                        ))}
                                    </Box>
                                </CardContent>
                                <Divider sx={{ marginY: 1, borderWidth: 2, borderColor: 'gray' }} />
                                <CardActions sx={{ justifyContent: 'center', gap: 4 }}>
                                    {userPermission.includes("Admin-cloudatlas-view") &&
                                        <Tooltip title="View Server Detail" arrow enterDelay={300} leaveDelay={200} placement="bottom">
                                            <RemoveRedEye
                                                onClick={() => {
                                                    navigate(`/atlasio/login`)
                                                    dispatch(setServerDetail(service))
                                                }}
                                                className='mircro-service-icon'
                                                style={{ color: "#1976d2" }}
                                            />
                                        </Tooltip>
                                    }
                                </CardActions>
                            </Card>
                        ))}
                    </Box>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={atlasServerListData.total ?? 0}
                    page={atlasServerListData.page ?? 0}
                    rowsPerPageOptions={[21, 50, 100]}
                    onPageChange={handleChangePage}
                    rowsPerPage={
                        rowsPerPage
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </StyledEngineProvider>
        </Paper>
    )
}

export default AtlasServerDashboard