import { Paper } from '@mui/material'
import React, { useState } from 'react'
import TerminalComponent from '../../components/SSHTerminal/Terminal'
import { useLocation } from 'react-router-dom';

const AtlasTerminal = () => {
    const query = useLocation();
    const [reloadSSHWindow, setReloadSSHWindow] = useState(false);

    return (
        <Paper>
            <TerminalComponent serverData={query?.state} reload={reloadSSHWindow} setReloadSSHWindow={setReloadSSHWindow} />
        </Paper>
    )
}

export default AtlasTerminal