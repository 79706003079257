import React, { useEffect, useState } from 'react'
import { Card, Paper, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import { TableHeadElement } from '../../components/Table/TableHeadElement'
import TableBodyElement from '../../components/Table/TableBodyElement'
import { useNavigate } from 'react-router-dom'

const AtlasTableComponent = (props) => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState();
    const [clickSSH, setClickSSH] = useState(false);
    const [terminalCredData, setTerminalCredData] = useState({});

    useEffect(() => {
        setFormData({ ...props.atlasServerListData });
    }, [props.atlasServerListData]);

    useEffect(() => {
        if (clickSSH) {
            navigate('/atlasio/login/terminal', {
                state: terminalCredData,
            })
        }
    }, [clickSSH, navigate, terminalCredData]);

    return (
        <Card sx={{ width: "100%" }}>
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 40%', fontSize: "20px !important" }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                    className="inner-headings"
                >
                    {props?.cardTitle}
                </Typography>
            </Toolbar>
            <StyledEngineProvider>
                <TableContainer component={Paper} className="table-outers" style={{ maxHeight: 700, overflowY: 'auto' }}>
                    <Table
                        sx={{ minWidth: 650, maxHeight: 650, borderCollapse: "collapse" }}
                        size="small" aria-label="a dense table"
                        stickyHeader
                    >
                        <TableHead className='table-head'>
                            <TableRow>
                                <TableHeadElement
                                    setHead={props?.tableColumns ? props?.tableColumns : []}
                                    order={props?.order}
                                    orderBy={props?.orderBy}
                                    sortFn={(event, id) =>
                                        props?.createSortHandler(event, id)
                                    }
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyElement
                                selectedColumns={props?.selectedColumns}
                                setData={formData?.data ? formData?.data : []}
                                showSerialNumber={false}
                                pageData={{
                                    limit:
                                        formData?.data?.length ?? Number(process.env.REACT_APP_PER_PAGE),
                                    page: formData?.page,
                                }}
                                atlasAction={true}
                                setTerminalCredData={setTerminalCredData}
                                setClickSSH={setClickSSH}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledEngineProvider>
        </Card>
    )
}

export default AtlasTableComponent