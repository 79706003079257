import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const AtlasIOApi = createApi({
    reducerPath: "atlasIOApi",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 0,
    tagTypes: ["atlasIOTag"],

    endpoints: (builder) => ({
        getAtlasServerList: builder.query({
            query: (pageData) => ({
                url: `/admin/atlas/list-server`,
                method: "GET",
                params: {
                    per_page: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    sort_order: pageData.order ?? process.env.REACT_APP_ORDER,
                    sort_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                },
            }),
            providesTags: ["atlasIOTag"],
        }),

        getAtlasServerLogin: builder.query({
            query: (id) => ({
                url: `/admin/atlas/list-server-logins`,
                method: "GET",
                params: {
                    server_id: id,
                },
            }),
            providesTags: ["atlasIOTag"],
        }),

    })
});

export const {
    useGetAtlasServerListQuery,
    useGetAtlasServerLoginQuery,
} = AtlasIOApi;
