import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Backdrop, Box, Container, debounce, Fade, Modal, Paper, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { SearchElement } from '../../components/Search/Search';
import { TableHeadElement } from '../../components/Table/TableHeadElement';
import TableBodyElement from '../../components/Table/TableBodyElement';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeleteServerMutation, useGetServerManagementListQuery } from '../../redux/apis/ServerManagementApi';
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox';
import AssignServer from './AssignServer';
import { useSelector } from 'react-redux';

const tableColumns = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "user_name",
        sort: true,
        label: "Username",
    },
    {
        id: "server_name",
        sort: true,
        label: "Servers",
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];
let initialOrder = process.env.REACT_APP_ORDER;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 10,
    borderRadius: 2,
};

const ServerDashboard = () => {
    const initialState = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE) ?? 20,
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };

    const userPermission = useSelector((state) => state.auth.permissions);
    let navigate = useNavigate();
    let query = useLocation();

    const [isClose, setIsClose] = useState(false);
    const [Id, setId] = useState("");
    const [open, setOpen] = useState(false);
    const [orderBy, setOrderBy] = useState(process.env.REACT_APP_ORDER_BY);
    const [order, setOrder] = useState(initialOrder);
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(initialState.per_page);
    const [serverList, setServerList] = useState(initialState);
    const [deleteUser] = useDeleteServerMutation();
    const [serverListData, setServerListData] = useState({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });

    const UserListApiCall = useGetServerManagementListQuery({
        per_page: serverList.per_page ?? rowsPerPage,
        page: query.state ?? serverList.page,
        order: serverList.order ?? order,
        order_by: serverList.order_by ?? orderBy,
        searchText: serverList.searchText ?? search,
    });

    useEffect(() => {
        if (UserListApiCall?.data?.success) {
            let data = UserListApiCall?.data?.data?.data;
            setServerListData({
                data: data,
                page: UserListApiCall?.data?.data?.current_page - 1,
                last_page: UserListApiCall?.data?.data?.last_name,
                total: UserListApiCall?.data?.data?.total,
            });
        }
    }, [UserListApiCall]);

    const memoizedSetOpen = useCallback((value) => setOpen(value), []);
    const memoizedSetId = useCallback((value) => setId(value), []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setServerList({
            ...serverList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (event, property) => {
        if (property !== ("action")) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (event, newPage) => {
        setServerList({ ...serverList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setServerList({
            ...serverList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
    };

    const searchHandler = useMemo(() => {
        return debounce((event) => {
            setSearch(event.target.value);
            setServerList({
                page: 1,
                per_page: serverList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, [serverList]);

    const confirmBoxClose = (res) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteUser(Id);
        } else {
            setIsClose(res);
        }
    };

    return (
        <>
            {/* Hide Scrollbars */}
            <style>
                {`
                    .table-outers::-webkit-scrollbar {
                        display: none;
                    }

                    .table-outers {
                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;     /* Firefox */
                    }
                `}
            </style>

            <Paper className="table-outers">
                <Toolbar>
                    <Typography
                        sx={{ flex: '1 1 40%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        className="inner-headings"
                    >
                        Server Dashboard
                    </Typography>

                    <div className="flex-boxinner">
                        <Typography sx={{ flex: '1 1 auto' }}>
                            <SearchElement
                                searchFn={searchHandler}
                                searchTag={`Search Server...`}
                            />
                        </Typography>
                    </div>
                </Toolbar>

                <StyledEngineProvider>
                    <TableContainer
                        component={Paper}
                        className="table-outers"
                        style={{
                            maxHeight: 700,   // Set the max height
                            overflowY: 'auto',  // Enable vertical scrolling
                        }}
                    >
                        <Table
                            sx={{ minWidth: 650, borderCollapse: "collapse" }}
                            size="small"
                            aria-label="a dense table"
                            stickyHeader
                        >
                            <TableHead className='table-head'>
                                <TableRow>
                                    <TableHeadElement
                                        setHead={tableColumns ? tableColumns : []}
                                        order={order}
                                        orderBy={orderBy}
                                        sortFn={(event, id) =>
                                            createSortHandler(event, id)
                                        }
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "user_name",
                                        "server_name"
                                    ]}
                                    setData={serverListData.data ? serverListData.data : []}
                                    {...(userPermission.includes("Admin-server-unassign") && {
                                        deleteFn: (id) => {
                                            setIsClose(true);
                                            setId(id);
                                        },
                                    })}
                                    pageData={{
                                        limit: rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                        page: serverListData.page,
                                    }}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={serverListData.total ?? 0}
                        page={serverListData.page ?? 0}
                        rowsPerPageOptions={[20, 50, 100]}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </StyledEngineProvider>

                {open && (
                    <Container maxWidth={false} className='p-0'>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{ backdrop: { timeout: 500 } }}
                        >
                            <Fade in={open}>
                                <Box sx={style}>
                                    <AssignServer id={Id} setOpen={memoizedSetOpen} setId={memoizedSetId} />
                                </Box>
                            </Fade>
                        </Modal>
                    </Container>
                )}

                <ConfirmBox
                    title="Server Delete Confirmation"
                    message="Are you sure, you want to delete this Server?"
                    open={isClose}
                    onClose={confirmBoxClose}
                />
            </Paper>
        </>
    );
};

export default ServerDashboard;
