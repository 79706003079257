import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const DashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 0,
    tagTypes: ["dashboardTag"],

    endpoints: (builder) => ({
        getDashboardDetail: builder.query({
            query: (mtuser) => ({
                url: `/admin/dashboard`,
                method: "GET",
            }),
            providesTags: ["dashboardTag"],
        }),
    })
});

export const {
    useGetDashboardDetailQuery,
} = DashboardApi;
