import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { MaterialUIControllerProvider } from "./context";
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { removeAlert, setLoader, setAlert } from './redux/slice/AlertBoxSlice';
import { setAuthLogout } from './redux/slice/AuthSlice';

const Interceptor = () => {
  const dispatch = useDispatch();
  const showAlert = (error = false, message = "") => {
    dispatch(
      setAlert({
        type: error ? "error" : "success",
        message,
      })
    );
    setTimeout(() => {
      dispatch(removeAlert(0));
    }, 2000);
  };

  axios.interceptors.request.use(
    (request) => {
      dispatch(setLoader({ isLoading: true }));
      return request;
    },
    (error) => error
  );

  axios.interceptors.response.use(
    (response) => {
      if (response?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        // window.location.reload();
      }
      let splitUrl = response?.config.url?.split("/");
      let urlData = splitUrl[splitUrl?.length - 1];

      if (urlData === 'login' && response?.status !== 200) {
        let errorMsg = Object.values(response?.data?.error);
        showAlert(true, errorMsg)
      } else {
        if (response.config.method !== "get") {
          showAlert(response?.status !== 200, response.data?.message);
        }
      }
      dispatch(setLoader({ isLoading: false }));
      return response;
    },
    (error) => {
      dispatch(setLoader({ isLoading: false }));
      showAlert(true, error.response?.data.message ?? error.message);
      if (error.response?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        persistor.purge();
        // window.location.reload();
      }
      // if(error.response?.status === 500) {
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   dispatch(setAuthLogout());
      //   // window.location.href = '/login';
      // }
      return error.response;
    }
  );
  return <></>;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <Interceptor />
            <App />
          </MaterialUIControllerProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
