import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    server_detail: '',
};

export const AtlasSlice = createSlice({
    name: 'atlasio',
    initialState,
    reducers: {
        setServerDetail: (state, action) => ({ ...state, server_detail: action.payload }),
    },
});

export const { setServerDetail } = AtlasSlice.actions;

export default AtlasSlice.reducer;
