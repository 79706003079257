import storage from 'redux-persist/lib/storage'; // Local storage
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AuthApi } from './apis/AuthApi';
import AlertBoxReducer from "./slice/AlertBoxSlice";
import AuthSliceReducer from './slice/AuthSlice';
import userRoleReducer from './slice/userRoleSlice';
import AtlasReducer from './slice/AtlasSlice';
import { DashboardApi } from './apis/DashboardApi';
import { MicroServiceApi } from './apis/MicroServiceApi';
import { UserManagementApi } from './apis/UserManagementApi';
import { ServerManagementApi } from './apis/ServerManagementApi';
import { AtlasIOApi } from './apis/AtlasIOApi';

import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';

const persistConfig = {
  key: 'root',
  storage, // Use local storage
  whitelist: ['auth', 'alertbox', 'userRole', 'atlas'], // Persist these reducers
  blacklist: [
    AuthApi.reducerPath,
    DashboardApi.reducerPath,
    MicroServiceApi.reducerPath,
    UserManagementApi.reducerPath,
    ServerManagementApi.reducerPath,
    AtlasIOApi.reducerPath,
  ],
};

const appReducer = combineReducers({
  userRole: userRoleReducer,
  alertbox: AlertBoxReducer,
  auth: AuthSliceReducer,
  atlas: AtlasReducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [DashboardApi.reducerPath]: DashboardApi.reducer,
  [MicroServiceApi.reducerPath]: MicroServiceApi.reducer,
  [UserManagementApi.reducerPath]: UserManagementApi.reducer,
  [ServerManagementApi.reducerPath]: ServerManagementApi.reducer,
  [AtlasIOApi.reducerPath]: AtlasIOApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(AuthApi.middleware)
      .concat(DashboardApi.middleware)
      .concat(MicroServiceApi.middleware)
      .concat(UserManagementApi.middleware)
      .concat(ServerManagementApi.middleware)
      .concat(AtlasIOApi.middleware),
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };
