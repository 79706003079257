import Dashboard from "../views/dashboard/Dashboard";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ComputerIcon from '@mui/icons-material/Computer';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CloudIcon from '@mui/icons-material/Cloud';
import PersonIcon from '@mui/icons-material/Person';
import UserDashboard from "../views/usermanagement/UserDashboard";
import AddEditUser from "../views/usermanagement/AddEditUser";
import MicroServicesDashboard from "../views/microservices/Dashboard";
import ServerDashboard from "../views/servermanagement/ServerDashboard";
import AssignServer from "../views/servermanagement/AssignServer";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AtlasServerDashboard from "../views/atlas.io/AtlasServerDashboard";
import AtlasServerLogin from "../views/atlas.io/AtlasServerLogin";
import AtlasTerminal from "../views/atlas.io/AtlasTerminal";

const routes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <SpaceDashboardIcon />,
        route: "/dashboard",
        component: <Dashboard />,
    },
    {
        type: "collapse",
        name: "MicroServices",
        key: "microservice",
        icon: <ComputerIcon />,
        component: <MicroServicesDashboard />,
        route: "/microservice",
        permissions: ["Admin-microservice-view"]
    },
    {
        type: "collapse",
        name: "Atlas IO",
        key: "atlasio",
        icon: <LibraryBooksIcon />,
        component: <AtlasServerDashboard />,
        route: "/atlasio",
        permissions: ["Admin-cloudatlas-view"]
    },
    {
        type: "route",
        name: "Servers Login Report",
        key: "atlasio/login",
        icon: <ComputerIcon />,
        component: <AtlasServerLogin />,
        route: "/atlasio/login",
        permissions: ["Admin-cloudatlas-view"]
    },
    {
        type: "route",
        name: "Servers Login Report",
        key: "atlasio/login",
        icon: <ComputerIcon />,
        component: <AtlasTerminal />,
        route: "/atlasio/login/terminal",
        permissions: ["Admin-cloudatlas-view"]
    },
    {
        type: "collapse",
        name: "Cloudflare",
        key: "cloudflare",
        icon: <CloudIcon />,
        route: "/cloudflare",
        component: <Dashboard />,
        permissions: []
    },
    {
        type: "collapse",
        name: "Securi",
        key: "securi",
        icon: <CloudIcon />,
        route: "/securi",
        component: <Dashboard />,
        permissions: []
    },
    {
        type: "collapse",
        name: "User Management",
        key: "user-management",
        icon: <PersonIcon />,
        children: [
            {
                type: "collapse",
                name: "User Dashboard",
                key: "user-management/user-dashboard",
                icon: <SpaceDashboardIcon />,
                component: <UserDashboard />,
                route: "/user-management/user-dashboard",
                permissions: ["Admin-user-view"]
            },
            {
                type: "collapse",
                name: "Add User",
                key: "user-management/add",
                icon: <ComputerIcon />,
                component: <AddEditUser />,
                route: "/user-management/add",
                permissions: ["Admin-user-add"]
            }
        ],
    },
    {
        type: "collapse",
        name: "Server Management",
        key: "server-management",
        icon: <AssignmentTurnedInIcon />,
        children: [
            {
                type: "collapse",
                name: "Dashboard",
                key: "server-management/server-dashboard",
                icon: <SpaceDashboardIcon />,
                component: <ServerDashboard />,
                route: "/server-management/server-dashboard",
                permissions: ["Admin-server-assign-view"]
            },
            {
                type: "collapse",
                name: "Assign Server",
                key: "server-management/assign-server",
                icon: <ComputerIcon />,
                component: <AssignServer />,
                route: "/server-management/assign-server",
                permissions: ["Admin-server-assign"]
            }
        ],
    },
];
export default routes;
