import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setAuth, setUserDetail, setUserPermissions } from '../../redux/slice/AuthSlice';
import { userRoleAction } from '../../redux/slice/userRoleSlice';
import './Googlecallback.css';

function GoogleCallback() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_URL}/api/auth/google/callback${location?.search}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data?.success) {
                    const { token, role_id, name, email, last_login_ip, permissions } = data?.data;

                    if (role_id === 1 || role_id === 2) {
                        localStorage.setItem('token', token);
                        dispatch(setAuth(role_id));
                        dispatch(userRoleAction(role_id));

                        const userData = {
                            name: name ?? '',
                            email: email ?? '',
                            role_id: role_id ?? 0,
                            last_login_ip: last_login_ip ?? '',
                        };
                        // localStorage.setItem('user', JSON.stringify(userData));
                        dispatch(setUserDetail(userData));
                        dispatch(setUserPermissions(permissions ?? []));
                        navigate("/dashboard");
                    } else {
                        localStorage.clear();
                        navigate('/login');
                    }
                } else {
                    localStorage.clear();
                    navigate('/login');
                }
            })
            .catch(error => {
                console.log(error);
                localStorage.clear();
                navigate('/login');
            });
    }, [dispatch, navigate, location]);

    return (
        <div class="loading">Loading&#8230;</div>
    )
}
export default GoogleCallback;