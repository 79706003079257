import React, { useCallback } from 'react'
import { Button, TableCell, TableRow, Tooltip } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
    DeleteForeverRounded,
    RemoveRedEye
} from "@mui/icons-material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CreateIcon from '@mui/icons-material/Create';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch } from 'react-redux';
import { removeAlert, setAlert } from '../../redux/slice/AlertBoxSlice';

const TableBodyElement = (props) => {
    const iconStyles = {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "center",
    };
    const dispatch = useDispatch();
    // const [expandedRows, setExpandedRows] = useState({});

    const iconColorMap = {
        success: { icon: <ThumbUpIcon />, color: "green" },
        fail: { icon: <ThumbDownIcon />, color: "red" },
        progress: { icon: <AutorenewIcon />, color: "black" },
        created: { icon: <CreateIcon />, color: "green" },
        skipped: { icon: <SkipNextIcon />, color: "green" },
    };

    let tableColumnWidth = props.styleFn;

    const tableColumnsData = useCallback((columnsData) => {
        const variableType = ["object"];
        if (variableType?.includes(typeof columnsData)) {
            return columnsData ? (columnsData).join(", ") : "";
        }
        return columnsData;
    }, []);

    const dateFormatter = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp);
        const year = date.getFullYear() ?? ' ';
        const month = (date.getMonth() + 1).toString().padStart(2, '0') ?? ' ';
        const day = date.getDate().toString().padStart(2, '0') ?? ' ';
        const formattedDateTime = `${day}-${month}-${year}`;
        return formattedDateTime;
    }, []);

    // const truncateText = (text, maxLength, rowIndex) => {
    //     const isTruncated = !expandedRows[rowIndex];
    //     if (text === null) {
    //         return text;
    //     }
    //     if (text?.length <= maxLength) return text;

    //     const toggleTruncate = () => {
    //         setExpandedRows((prevState) => ({
    //             ...prevState,
    //             [rowIndex]: !prevState[rowIndex],
    //         }));
    //     };

    //     return (
    //         <>
    //             {isTruncated ? text.slice(0, maxLength) + "..." : text}
    //             <Box
    //                 onClick={toggleTruncate}
    //                 style={{
    //                     color: "blue",
    //                     cursor: "pointer",
    //                     textDecoration: "underline",
    //                 }}
    //                 tabIndex={0}
    //                 onKeyDown={(e) => {
    //                     if (e.key === "Enter" || e.key === " ") {
    //                         toggleTruncate();
    //                     }
    //                 }}
    //             >
    //                 {isTruncated ? " more" : " less"}
    //             </Box>
    //         </>
    //     );
    // };

    const handleCopy = (text) => {
        if (!navigator.clipboard) {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            dispatch(
                setAlert({
                    type: "success",
                    message: 'Password Copied',
                })
            )
            setTimeout(() => {
                dispatch(removeAlert(0));
            }, 5000);
        } else {
            navigator.clipboard.writeText(text)
                .then(() => {
                    dispatch(
                        setAlert({
                            type: "success",
                            message: 'Password Copied',
                        })
                    );
                    setTimeout(() => {
                        dispatch(removeAlert(0));
                    }, 5000);
                })
                .catch(err => console.error('Failed to copy:', err));
        }
    };

    const renderTableCellContent = (columnName, columnData, index) => {
        switch (columnName) {
            case 'createdAt':
                return dateFormatter(columnData);
            case 'job_name':
                return (
                    <a href={columnData?.job_url} target="_blank" rel="noreferrer">
                        {tableColumnsData(columnData)}
                    </a>
                );
            case 'job_status': {
                const statusIcon = iconColorMap[columnData];
                if (statusIcon) {
                    return (
                        <div style={iconStyles}>
                            <span
                                style={{
                                    color: statusIcon.color,
                                    fontSize: "large",
                                    marginTop: "2px",
                                }}
                            >
                                {statusIcon.icon}
                            </span>
                            {columnData}
                        </div>
                    );
                }
                return tableColumnsData(columnData);
            }
            case 'server_name':
                return columnData?.hostname + " (" + columnData?.ip_address + ") "

            case 'password':
                return <div>
                    {tableColumnsData(columnData)} <ContentCopyIcon onClick={() => handleCopy(columnData)} sx={{ cursor: "pointer" }} />
                </div>
            default:
                return tableColumnsData(columnData);
        }
    };

    function generateUniqueId() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
    const handleSSH = (item) => {
        const idconnection = generateUniqueId();
        let dataSend = {
            "idconnection": idconnection,
            "server": item?.ip_url,
            "port": item?.port,
            "user": item?.user,
            "password": item?.password,
        }
        props.setTerminalCredData(dataSend);
        props.setClickSSH(true);
    }

    return (
        <>
            {(props.setData ? props.setData : []).map(
                (column, index) => (
                    <TableRow key={index}
                        style={
                            column.is_debited !== undefined
                                ? { backgroundColor: column.is_debited ? "#bf3030" : "#399861" }
                                : {}
                        }
                    >
                        {props.showSerialNumber !== false &&
                            <TableCell className='table-body-cell'>
                                {props.pageData &&
                                    props.pageData?.limit * props.pageData?.page + index + 1}
                            </TableCell>
                        }
                        {props.selectedColumns.map((columnName, indx) => (
                            <TableCell
                                key={columnName}
                                align="left"
                                style={{ width: tableColumnWidth }}
                                className='table-body-cell'
                            >
                                {renderTableCellContent(columnName, column[columnName], index)}
                            </TableCell>
                        ))}
                        {!props?.showAction && (
                            <TableCell align="left" className='table-body-cell'>
                                {typeof props.deployServiceFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) &&
                                    (column?.service_status === 1 ||
                                        column?.service_status === undefined) && (
                                        <Tooltip title="Run Deploy" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.deployServiceFn === "function" &&
                                                    props.deployServiceFn(column)
                                                }
                                                className="action-btn"
                                            >
                                                <RocketLaunchIcon />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                                {typeof props.editFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) && (
                                        <Tooltip title="Edit" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.editFn === "function" &&
                                                    props.editFn(column.id)
                                                }
                                                className="action-btn"
                                            >
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                                {typeof props.deleteFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) && (
                                        <Tooltip title="" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.deleteFn === "function" &&
                                                    props.deleteFn(column.id)
                                                }
                                                className="action-btn"
                                                style={{ color: "red" }}
                                            >
                                                <DeleteForeverRounded />
                                            </Button>
                                        </Tooltip>
                                    )
                                }

                                {typeof props.viewServerLoginFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) && (
                                        <Tooltip title="Server Login" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.viewServerLoginFn === "function" &&
                                                    props.viewServerLoginFn(column)
                                                }
                                                className="action-btn"
                                            >
                                                <RemoveRedEye />
                                            </Button>
                                        </Tooltip>
                                    )
                                }

                                {/* Atlas Table Actions */}
                                {props.atlasAction &&
                                    <Tooltip>
                                        {(column['login_type'] === 'SSH/SFTP/FTP') && (!column['ip_url'].match(/^sftp:\/\//)) && (!column['ip_url'].match(/^ftp:\/\//)) &&
                                            <button
                                                className='table-action-ssh-btn'
                                                onClick={() => handleSSH(column)}>
                                                SSH
                                            </button>
                                        }
                                        {(column['login_type'] === 'SSH/SFTP/FTP') && (column['ip_url'].match(/^sftp:\/\//)) &&
                                            <a target="_blank" rel="noopener noreferrer" href={`sftp://${column?.user}:${column?.password}@${column?.ip_url.replace(/^sftp:\/\//i, '')}:${column?.port}`}>
                                                <button id="sftp-tooltip" data-tooltip-place="bottom-start" data-tooltip-content="To achieve one-click SFTP access, you can either download WinSCP software or utilize alternative FTP software, copying the username and password from this location." data-tooltip-variant="dark" className='table-action-ssh-btn'>
                                                    SFTP
                                                </button>
                                                <Tooltip sx={{ borderRadius: "10px", padding: 5 }} anchorSelect="#sftp-tooltip" />
                                            </a>
                                        }
                                        {(column['login_type'] === 'SSH/SFTP/FTP') && (column['ip_url'].match(/^ftp:\/\//)) &&
                                            <a target="_blank" rel="noopener noreferrer" href={`ftp://${column?.user}:${column?.password}@${column?.ip_url.replace(/^ftp:\/\//i, '')}:${column?.port}`}>
                                                <button id="ftp-tooltip" data-tooltip-place="bottom-start" data-tooltip-content="To achieve one-click FTP access, you can either download WinSCP software or utilize alternative FTP software, copying the username and password from this location." data-tooltip-variant="dark" className='table-action-ssh-btn'>
                                                    FTP
                                                </button>
                                                <Tooltip sx={{ borderRadius: "10px", padding: 5 }} anchorSelect="#ftp-tooltip" />
                                            </a>
                                        }
                                        {column['login_type'].includes('VIRTUALMIN') &&
                                            <form method="post" target="_blank" action={`${column?.ip_url || ''}/session_login.cgi`}>
                                                <div data-toggle="tooltip" data-placement="top" data-original-title="Virtaulmin" >
                                                    <input type="hidden" name="notestingcookie" value="1" />
                                                    <input type="hidden" name="page" value="/" />
                                                    <input type="hidden" name="user" value={column?.user} />
                                                    <input type="hidden" name="pass" value={column?.password} />
                                                    <button type="submit" className="badge badge-success table-action-ssh-btn">
                                                        Vmin
                                                    </button>
                                                </div>
                                            </form>
                                        }
                                        {column['login_type'].includes('CPANEL') &&
                                            <form method="post" target="_blank" action={`${column?.ip_url || ""}/login/`}>
                                                <div data-toggle="tooltip" data-placement="top" data-original-title="cPanel" >
                                                    <input type="hidden" name="user" value={column?.user} />
                                                    <input type="hidden" name="pass" value={column?.password} />
                                                    <button type="submit" className="badge badge-success table-action-ssh-btn">
                                                        cPanel
                                                    </button>
                                                </div>
                                            </form>
                                        }
                                        {column['login_type'].includes('MAGENTO') &&
                                            <form method="post" target="_blank" action={`${column?.ip_url || ""}`}>
                                                <div data-toggle="tooltip" data-placement="top" data-original-title="magento" >
                                                    <button type="submit" className="badge badge-success table-action-ssh-btn">
                                                        M-admin
                                                    </button>
                                                </div>
                                            </form>
                                        }
                                        {column['login_type'].includes('WP-LOGIN') &&
                                            <form method="post" target="_blank" action={`${column?.ip_url || ""}`}>
                                                <div data-toggle="tooltip" data-placement="top" data-original-title="wp-login" >
                                                    <button type="submit" className="badge badge-success table-action-ssh-btn">
                                                        WP-admin
                                                    </button>
                                                </div>
                                            </form>
                                        }
                                        {column['login_type'].includes('MySQL/MSSQL') &&
                                            <form method="post" target="_blank" id="login_form" action={`${column?.ip_url || ""}/index.php`} name="login_form">
                                                <input type="hidden" name="pma_username" id="input_username" value={column?.user} />
                                                <input type="hidden" name="pma_password" id="input_password" value={column?.password} />
                                                <button type="submit" id="input_go" className="badge badge-success table-action-ssh-btn">
                                                    PMA
                                                </button>
                                            </form>
                                        }
                                        {column['login_type'].includes('Web Login') &&
                                            <form method="post" target="_blank" action={`${column?.ip_url || ""}`}>
                                                <div data-toggle="tooltip" data-placement="top" data-original-title="wl" >
                                                    <button type="submit" className="badge badge-success table-action-ssh-btn">
                                                        Open
                                                    </button>
                                                </div>
                                            </form>
                                        }
                                        {!['SSH/SFTP/FTP', 'VIRTUALMIN', 'CPANEL', 'MAGENTO', 'WP-LOGIN', 'MySQL/MSSQL', 'Web Login'].includes(column['login_type']) &&
                                            <button
                                                className='table-action-ssh-btn'
                                            >
                                                No-Action
                                            </button>
                                        }
                                    </Tooltip>
                                }
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            {props?.setData?.length === 0 && (
                <TableRow>
                    <TableCell style={{ textAlign: "center", justifyContent: "center", width: "100%" }} colSpan={(props?.selectedColumns?.length + 2) ?? 12}>
                        <h4>No Record found</h4>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default TableBodyElement