import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FullLogo from '../../assets/svgs/FullLogo';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDoLoginMutation } from '../../redux/apis/AuthApi';
import { useDispatch } from 'react-redux';
import { setAuth, setUserDetail, setUserPermissions } from '../../redux/slice/AuthSlice';
import { userRoleAction } from '../../redux/slice/userRoleSlice';
import Spinner from '../../components/spinner/Spinner';
import { Alert, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';


const GoogleButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.getContrastText('#ffffff'),
  width: "100%",
  backgroundColor: '#ffffff',
  borderColor: '#673ab7',
  fontSize: "small",
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.35)',
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
}));

const Login = () => {

  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const [errorState, setErrorState] = useState({
    status: false,
    msg: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [loginUrl, setLoginUrl] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [doLogin, result] = useDoLoginMutation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/google`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong!');
      })
      .then((data) => setLoginUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (result?.isSuccess && result?.data?.success) {
      const data = result?.data?.data;
      const { role_id } = data;
      if (role_id === 1 || role_id === 2) {
        dispatch(setAuth(role_id));
        dispatch(userRoleAction(role_id));

        const userData = {
          name: data?.name ?? '',
          email: data?.email ?? '',
          role_id: role_id ?? 0,
          last_login_ip: data?.last_login_ip ?? '',
        };
        // localStorage.setItem('user', JSON.stringify(userData));
        dispatch(setUserDetail(userData));
        dispatch(setUserPermissions(data?.permissions ?? []));
        setLoading(false);
        navigate("/dashboard");
      } else {
        let msg = 'Unauthorized';
        setErrorState({
          status: true,
          msg: msg
        });
        setLoading(false);
        localStorage.clear();
      }
    }

    if (result?.isSuccess && !result?.data?.success) {
      setFormData({
        email: '',
        password: '',
      });
      setLoading(false);
    }
    if (result?.isError) {
      setLoading(false);
      /* let msg = result?.error?.data?.message ?? ''; */
      let msg = 'Unauthorized';
      setErrorState({
        status: true,
        msg: msg
      });
    }
  }, [result, dispatch, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('login');
    let loginData = { ...formData, token: token, type: 1 };
    doLogin(loginData);
  };

  return (
    <Grid container component="main" className='main-grid'>
      <Grid item sm={6} elevation={6} square className='login_container'>
        <Box sx={{ my: 12, mx: 4 }}>
          <Typography component="h1" variant="h4" sx={{ fontWeight: "550" }}>
            Sign In
          </Typography>
          <Typography>
            Enter your email and password to sign in!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            {errorState?.status &&
              <Alert variant="filled" severity="error" onClose={() => { setErrorState({ status: false, msg: "" }); }}>
                {errorState?.msg ?? ''}
              </Alert>
            }
            <TextField
              margin="normal"
              required
              fullWidth
              id="outlined-basic"
              variant="outlined"
              type='email'
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange}
              value={formData.email}
            />
            <FormControl sx={{ width: '100%', mt: 1 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange}
                value={formData.password}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            {loading ? (
              <div className="loaderContainer">
                <div>
                  <Spinner />
                </div>
              </div>
            ) :
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: "#fff" }}
              >
                Sign In
              </Button>
            }
            {/* Google Sign In */}
            {loginUrl != null && (
              <a href={loginUrl}>
                <GoogleButton
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                >
                  Sign in with Google
                </GoogleButton>
              </a>
            )}

            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        sm={6}
        className='login_image_container'
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        }}
      >
        <div className="logo_img_container">
          <div className="logo_img">
            <FullLogo />
          </div>
        </div>
      </Grid>
    </Grid >
  );
}

export default Login;