
import { useState, useEffect, useCallback } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";
// import MDBox from "./components/MDBox";
import Sidenav from "./examples/Sidenav/index";
import Configurator from "./examples/Configurator/index";
import theme from "./assets/theme/index";
import themeDark from "./assets/theme-dark/index";
import routes from "./routes/routes";
// import SettingsIcon from '@mui/icons-material/Settings';
import {
  useMaterialUIController,
  setMiniSidenav,
  // setOpenConfigurator 
} from "./context";
import GoogleReCaptchaProvider from "./views/auth/GoogleReCaptcha"
import GoogleCallback from "./views/auth/GoogleCallback";
import "./App.css";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import AlertBox from "./components/AlertBox/AlertBox";
import DashboardLayout from "./examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "./examples/Navbars/DashboardNavbar";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    // openConfigurator,
    sidenavColor,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [filteredRoute, setFilteredRoute] = useState(routes);
  const location = useLocation();
  const isLoading = useSelector((state) => state.alertbox.isLoading);
  const permission = useSelector((state) => state.auth.permissions);

  const filterRoutesByPermissions = useCallback((routes, userPermissions) =>
    routes.reduce((filteredRoutes, route) => {
      if (route.children) {
        const filteredChildren = filterRoutesByPermissions(route.children, userPermissions);
        if (filteredChildren.length > 0) {
          filteredRoutes.push({ ...route, children: filteredChildren });
        }
      } else if (!route.permissions || route.permissions.some((perm) => userPermissions.includes(perm))) {
        filteredRoutes.push(route);
      }
      return filteredRoutes;
    }, []), []);

  useEffect(() => {
    if (permission?.length > 0) {
      const filteredRoutes = filterRoutesByPermissions(routes, permission);
      setFilteredRoute(filteredRoutes);
    }
  }, [permission, filterRoutesByPermissions]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);


  // useEffect(() => {
  //   let userData = localStorage.getItem('user');
  //   if (JSON.parse(userData)) {
  //     dispatchDetail(setUserDetail(JSON.parse(userData)));
  //   }
  // }, [dispatchDetail])

  const getRoutes = (allRoutes) =>
    allRoutes.flatMap((route) => {
      if (route.collapse || route.children) {
        return route.children.flatMap((childRoute) => {
          // Check if the child route has subchildren
          if (childRoute.subchildren) {
            return childRoute.subchildren.map((subChildRoute) => (
              <Route
                exact
                path={subChildRoute.route}
                element={
                  <DashboardLayout>
                    <DashboardNavbar />
                    {subChildRoute.component}
                  </DashboardLayout>
                }
                key={subChildRoute.key}
              />
            ));
          }

          return (
            <Route
              exact
              path={childRoute.route}
              element={
                <DashboardLayout>
                  <DashboardNavbar />
                  {childRoute.component}
                </DashboardLayout>
              }
              key={childRoute.key}
            />
          );
        });
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <DashboardLayout>
                <DashboardNavbar />
                {route.component}
              </DashboardLayout>
            }
            key={route.key}
          />
        );
      }

      return null;
    });



  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit" style={{ marginTop: "2px", display: "flex", justifyContent: "center" }}>
  //       <SettingsIcon />
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {localStorage.getItem("token") && (<Box className={`loader-outer ${isLoading ? "addloader" : ""}`}>
        <div className="sound_wave">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </Box>
      )}
      <AlertBox />
      <CssBaseline />
      {localStorage.getItem("token") && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName="WebScoot Admin"
            routes={filteredRoute}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {localStorage.getItem("token") && layout === "vr" && <Configurator />}
      <Routes>
        {localStorage.getItem("token") && getRoutes(filteredRoute)}
        <Route path="/login" element={localStorage.getItem("token") ? <Navigate to={"/dashboard"} /> : <GoogleReCaptchaProvider />} />
        <Route path="/login" element={<GoogleReCaptchaProvider />} />
        <Route path="/auth/google/callback" element={<GoogleCallback />} />
        <Route path="*" element={localStorage.getItem("token") ? <Navigate to={"/dashboard"} /> : <Navigate to="/login" />} />
      </Routes>
    </ThemeProvider>
  );
}
