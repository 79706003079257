import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const UserManagementApi = createApi({
    reducerPath: "userManagementApi",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 0,
    tagTypes: ["userManagementTag"],

    endpoints: (builder) => ({
        getUserList: builder.query({
            query: (pageData) => ({
                url: `/admin/user/list`,
                method: "GET",
                params: {
                    per_page: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    sort_order: pageData.order ?? process.env.REACT_APP_ORDER,
                    sort_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                },
            }),
            providesTags: ["userManagementTag"],
        }),

        getListPermissions: builder.query({
            query: (role) => ({
                url: `/admin/user/list-permissions`,
                method: "GET",
                params: {
                    role: role
                },
            }),
            providesTags: ["userManagementTag"],
        }),

        getUserDetailById: builder.query({
            query: (id) => ({
                url: `/admin/user/info/${id}`,
                method: "GET",
            }),
            providesTags: ["userManagementTag"],
        }),

        addUser: builder.mutation({
            query: (data) => ({
                url: `/admin/user/adduser`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["userManagementTag"],
        }),

        editUser: builder.mutation({
            query: (data) => ({
                url: `/admin/user/edit/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["userManagementTag"],
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/admin/user/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["userManagementTag"],
        }),
    })
});

export const {
    useGetUserListQuery,
    useGetListPermissionsQuery,
    useGetUserDetailByIdQuery,
    useAddUserMutation,
    useEditUserMutation,
    useDeleteUserMutation,
} = UserManagementApi;
